<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <label>{{ $t('labels.from_status') }}</label>
            <v-select
              v-model='searchFields.from_status'
              :dir='$store.state.appConfig.layout.direction'
              :options='status_list'
              :reduce='item => item.value'
              label='label'
              class='w-100'
            />
          </b-col>
          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <label>{{ $t('labels.to_status') }}</label>
            <v-select
              v-model='searchFields.to_status'
              :dir='$store.state.appConfig.layout.direction'
              :options='status_list'
              :reduce='item => item.value'
              label='label'
              class='w-100'
            />
          </b-col>

          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <DatePicker
              :label="$t('labels.created_on')"
              :reset='true'
              v-model='created_at'
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :resource-name='resourceName'
      :singular-name='singularName'
      :filter-fields='searchFields'
      :showRealEstateDetailsPage='true'
      :permissionKey='"logs"'

    >
    </TableIndex>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'
import { websiteMainUrl } from '@/services/url.service'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'real-state-status-logs',
      singularName: 'real-state-status-logs',
      searchFields: {
        from_status: null,
        to_status: null,
        created_at: null,
      },
      created_at: null,
      status_list:[]
    }
  },
  created() {
    this.axios.get(`/model-list/real-estate-status`)
      .then(res => {
        this.status_list = res.data.data

        this.status_filter_list = res.data.data.filter(function(item) {
          return item.value !== 1 && item.value !== 12 && item.value !== 6
        })

      })
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function(e, field) {
      this.searchFields[field] = e
    }, 500),
  },

  watch: {
    'created_at': {
      handler(val) {
        if (this.searchFields['created_at'] == null && (val == '' || val == null)) {
          return true
        }

        this.searchFields['created_at'] = val
      },
    },
  },

}
</script>
